import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import Layout from "../components/layout"
import Button from "../components/button"
import FormatDate  from '../components/formatDate'
import { Fade } from "react-reveal" 
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'


const Flex = styled.div`
  position:relative;    
  margin:0 auto;
  max-width:1150px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction:row;
  padding-bottom: 10rem;
  gap:5rem;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction:column-reverse;
    gap:2rem;
    padding-bottom:4rem;
  } 
  h1 {
    font-size:5.7rem;
  }
`

const IllustrationDisque = styled.div`   
    margin-top:1rem;
    width:100%;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
    max-width:550px;
    margin-top:-5rem;
    position:sticky;
    top:2rem;
    
} 
.imageDisque {box-shadow: 0 8px 15px 0 ${props => props.theme.colors.gray};}
`

const ContentDisque = styled.div`   
    width:100%;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
    max-width:727px;
} `


const ButtonWrapper = styled.div`   
    margin-bottom:8rem;
    display:flex;
    gap:1.5rem;
    margin-top:2rem;
    flex-wrap:wrap;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
      
    } 
`


const AlbumApneeContent = ({data}) => {
  return (
    <Layout>
      <HelmetDatoCms >
        <meta name="robots" content="noindex, nofollow"></meta>
      </HelmetDatoCms>
      <h1 className="breadcrumb">Download</h1>
      <div className="container">
        <Flex>   
            <ContentDisque>
              <Fade up duration={700} distance="70px"><h1>{data.disque.titre}</h1></Fade>
              <ButtonWrapper>
              <Button external to = {data.disque.lienDownload}>Télecharger l'album</Button>
            </ButtonWrapper>
            </ContentDisque> 
            <IllustrationDisque> 
              <Fade down duration={700} distance="70px">
                <GatsbyImage alt={data.disque.titre} image={data.disque.image.gatsbyImageData} className="imageDisque"/>
              </Fade>
              <span className="date-small gray">{data.disque.titre} - <FormatDate date={data.disque.date}/></span>

            </IllustrationDisque> 
        </Flex>
    </div>

    </Layout>
  )
}

export default AlbumApneeContent


export const query = graphql`
  query DownloadQuery {

    disque:datoCmsDisque( slug: {eq: "apnee" }) {
      id
      titre
      date
      lienDownload
      image {
        gatsbyImageData (
          placeholder: BLURRED
        )
        alt
      }
    }
  }
  `